const config = {
  // 'basename': 빌드 시에만 설정. Breadcrumbs에 '/'을 추가하지 않음. '/'만 사용하지 말고, 비어있는 문자열('')을 사용함.
  // 예: '/berry-material-react/react/default'
  basename: "",

  // 애플리케이션의 기본 경로 설정
  defaultPath: "/",

  // UI 요소의 테두리 둥근 정도를 설정 (단위: px)
  borderRadius: 12,

  // 메인 외부 래퍼(wrapper)의 스타일 설정
  mainOutWrapper: {
    flexDirection: "column", // 주 축 방향을 세로로 설정
    justifyContent: "center", // 주 축의 중앙에 내용을 배치
    alignItems: "center", // 교차 축의 중앙에 내용을 배치
  },

  // 메인 내부 래퍼의 스타일 설정
  mainInnerWrapper: {
    flexDirection: "row", // 주 축 방향을 가로로 설정
    padding: "0 5vw", // 내부 여백 설정 (상, 하: 0, 좌, 우: 뷰포트 너비의 5%)
    width: "100%", // 너비 100% 설정
    display: "block", // 블록 레벨 요소로 표시
    flexGrow: 1, // 유연한 박스 항목으로서의 성장 비율
    maxWidth: "1280px", // 최대 너비 설정
  },

  // 메인 패딩 설정, 'mainInnerWrapper'의 패딩과 동일
  mainPadding: "0 5vw",

  // 네비게이션 바 상단 여백 설정
  navBarMarginTop: "3rem", // 상단 여백 3rem 설정
};

export default config;
