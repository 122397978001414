import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";

export const StyledListItem = styled(ListItem)`
  position: relative;
  background-color: #1c1c1c;
  color: #fff;
  &:hover {
    background-color: #333;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    .iconButton {
      visibility: visible;
    }
  }
  border-radius: 0;
  margin-bottom: 0;
  &:last-of-type {
    border-bottom-left-radius: ${(props) => (props.islast ? "8px" : "0px")};
    border-bottom-right-radius: ${(props) => (props.islast ? "8px" : "0px")};
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  width: 100%;
  text-align: left;
  padding-right: ${(props) => props.theme.spacing(6)};
  color: #fff;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
`;

export const MenuIcon = styled.span`
  margin-right: ${(props) => props.theme.spacing(1)};
  color: #fff;
`;

export const PrimaryText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 40px);
  color: #fff;
`;

export const SecondaryText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 40px);
  color: #bbb;
`;

// export const MoreIconWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #fff;
// `;
