// localStorageUtil.js

export const saveEmailToLocalStorage = (email) => {
  localStorage.setItem("savedEmail", email);
};

export const getEmailFromLocalStorage = () => {
  return localStorage.getItem("savedEmail") || "";
};

export const removeEmailFromLocalStorage = () => {
  localStorage.removeItem("savedEmail");
};
