//index.js
import { createTheme } from "@mui/material/styles";

// assets
import colors from "../assets/scss/_themes-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    page: color.page,
    backgroundDefault: "#FFF",
    background: color.primaryLight,
    darkTextPrimary: color.grey900,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.primaryMain,
    menuSelectedBack: color.page,
    divider: color.grey200,
    customization,
  };

  const themeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);

  // 전역 스타일을 위한 components 설정
  themes.components = {
    ...themes.components, // 기존 컴포넌트 스타일 유지
    MuiSelect: {
      styleOverrides: {
        root: {
          width: "100px",
          height: "20px",
          backgroundColor: color.primaryMain, // colors.scss에서 정의한 primaryMain 사용
          color: "white",
          "&:focus": {
            backgroundColor: color.primaryDark,
          },
          "& .MuiSvgIcon-root": {
            color: "white", // 드롭다운 화살표 색상
          },
        },
      },
    },
    ...componentStyleOverrides(themeOption),
  };

  return themes;
};

export default theme;
