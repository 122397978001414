// moemoechat/app-react/src/App.js
import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import Routes from "./routes"; // routing
import themes from "./themes"; // defaultTheme

import { useAtom } from "jotai";
import { modelAtom } from "store/atom";
import MetaSetter from 'components/common/MetaSetter'

const App = () => {
  const [, setModel] = useAtom(modelAtom);

  useEffect(() => {
    // 앱 실행 시 모델 초기화
    if (!sessionStorage.getItem("hasRun")) {
      const val = process.env.REACT_APP_MODE === "moemoe" ? "루나" : "꿈돌이";
      setModel(val);
      sessionStorage.setItem("hasRun", "true");
    }
  }, [setModel]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes()}>
        <CssBaseline />
        <MetaSetter />
        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
