import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai";

const basicMode = process.env.REACT_APP_MODE; // [mascot/moemoe]
const basicModel = basicMode === "moemoe" ? "루나" : "꿈돌이";
const basicChatData = {
  name: basicModel,
  history: {},
  prompt: "",
  emotion: "neutral",
  response: "",
  count: 10,
  score: 0,
  gender: "m",
};

// ATOM
export const modeAtom = atom(basicMode); // [실행 모드]
export const lockAtom = atom(true); // [락]
export const loadingStatusAtom = atom("default"); // default, isConnecting, isStreaming, (기본 => 연결중 => 전송중)
export const fixValueAtom = atom({ fixContent: "", fixIdx: -1 }); // 수정중인 chatData 값
export const ttsContentsAtom = atom({ ttsParagraph: "", ttsLines: [] });
export const lastPromptAtom = atom("");

// AtomWithStorage
export const modelAtom = atomWithStorage("model", basicModel); // [선택된 챗봇 모델]
export const modelsAtom = atomWithStorage("models", []); // [즐겨찾기모델]
export const viewAtom = atomWithStorage("view", 1); // [뷰 형태]
export const chatDataAtom = atomWithStorage("chatData", basicChatData); // [채팅 데이타]
export const genderAtom = atomWithStorage("gender", "m"); // [유저 성별]
export const isDarkAtom = atomWithStorage("isDark", true); // [다크모드]
export const isLoggedInAtom = atomWithStorage("isLoggedIn", false); // [로그인 상태]
export const topicsAtom = atomWithStorage("topics", []); // [토픽]
export const sideMenuOrderAtom = atomWithStorage("sideMenuOrder", [
  "favorites",
  "sessions",
  "storage",
]);
