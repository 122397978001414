// moemoechat/app-react/src/index.js
import React from "react";
import ReactDOM from "react-dom/client"; // createRoot를 사용하기 위해 경로가 변경됨

import { BrowserRouter } from "react-router-dom";
import { Provider as JotaiProvider } from "jotai";

import App from "./App";
import "./assets/scss/style.scss";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <JotaiProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </JotaiProvider>,
);
