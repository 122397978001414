import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import {
  saveEmailToLocalStorage,
  getEmailFromLocalStorage,
  removeEmailFromLocalStorage,
} from "./localStorageUtil";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { playSingleAudio } from "utils/audioControl/playAudio";
import { login } from "apis/LibraryAPI";

export const LoginComp = ({ isLoggedIn, setIsLoggedIn, loginComp, setLoginComp }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // 입력 값
  const [usermail, setusermail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // 입력 체크박스
  const [isRememberEmailChecked, setIsRememberEmailChecked] = useState(false);
  const [isAutoLoginChecked, setIsAutoLoginChecked] = useState(false);

  // 입력 상태
  const [error, setError] = useState("");

  const handleLoginClick = async () => {
    try {
      const response = await login(usermail, password);
      // 로그인 성공
      if (response.status === 201) {
        setIsLoggedIn(true);
        setLoginComp(false);
        navigate("/mypage");
        await playSingleAudio("fancy.mp3");

        // 이메일 저장
        if (isRememberEmailChecked) {
          saveEmailToLocalStorage(usermail);
        } else {
          removeEmailFromLocalStorage();
        }
      }
    } catch (error) {
      // 로그인 실패
      setError("로그인에 실패했습니다. 다시 시도해 주세요.");
    }
  };

  // 창 닫기
  const handleCancel = () => {
    setLoginComp(false);
  };

  // 회원가입.ID찾기.비밀번호재설정
  const handleNavigation = (tabIndex) => {
    setLoginComp(false);
    navigate(`/account?tab=${tabIndex}`);
  };

  // const accBtns = {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   width: "100%",
  //   margin: "10px 0",
  //   gap: "10px",
  // };

  // const accBtn = {
  //   cursor: "pointer",
  //   padding: "5px",
  //   width: "50%",
  //   textAlign: "center",
  //   backgroundColor: "#8d8ddb",
  //   textShadow: "0 0 1px black",
  // };

  // 저장된 이메일 불러오기
  useEffect(() => {
    const savedEmail = getEmailFromLocalStorage();
    if (savedEmail) {
      setusermail(savedEmail);
      setIsRememberEmailChecked(true);
    }
  }, []);

  return (
    /*  
      ┌──────────────────────────────────────────────┐
      │                     로그인                   │
      ├──────────────────────────────────────────────┤
      │ 이메일 계정  _______________________________ │
      │ 비밀번호     _______________________________ │
      │                                              │
      │       [ ] 이메일 저장   [ ] 자동 로그인       │
      │                                              │
      │                 [ 로그인 ]                   │
      │                                              │
      │                 [ 회원가입 ]                 │
      │ ──────────────────────────────────────────── │
      │ [ ID 찾기 ]     [ 비밀번호 재설정 ]           │
      │                                              │
      │                    [ 취소 ]                  │
      └──────────────────────────────────────────────┘
    */
    <Dialog
      open={loginComp}
      onClose={handleCancel}
      className={classes.dialog}
      maxWidth="xs"
      fullWidth
      PaperProps={{ classes: { root: classes.paper }, style: { borderRadius: "20px" } }}
    >
      <DialogTitle
        className={classes.dialogTitle}
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}
      >
        로그인
      </DialogTitle>

      <DialogContent className={classes.dialogContent} style={{ padding: "20px 24px" }}>
        {/* ID 입력창 */}
        <TextField
          label="이메일 계정"
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={usermail}
          onChange={(e) => setusermail(e.target.value)}
          InputProps={{ style: { borderRadius: "10px", marginBottom: "15px" } }}
        />
        {/* PW 입력창 */}
        <TextField
          label="비밀번호"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
            style: { borderRadius: "10px" },
          }}
        />
        {error && (
          <Typography color="error" className={classes.errorText}>
            {error}
          </Typography>
        )}

        {/* 설정 체크박스 */}
        <Grid container justifyContent="space-between" style={{ marginBottom: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRememberEmailChecked}
                onChange={(e) => setIsRememberEmailChecked(e.target.checked)}
                color="primary"
              />
            }
            label="이메일 저장"
            style={{ margin: 0 }} // 여백을 없애기 위한 스타일 추가
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isAutoLoginChecked}
                onChange={(e) => setIsAutoLoginChecked(e.target.checked)}
                color="primary"
              />
            }
            label="자동 로그인"
            style={{ margin: 0 }} // 여백을 없애기 위한 스타일 추가
          />
        </Grid>

        {/* 로그인 버튼 */}
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            handleLoginClick();
          }}
          style={{ borderRadius: "10px", marginBottom: "15px", backgroundColor: "#8888ff" }}
        >
          로그인
        </Button>

        {/* 회원가입 버튼 */}
        <Button
          variant="outlined"
          fullWidth
          onClick={() => handleNavigation(0)}
          style={{ borderRadius: "10px", marginBottom: "10px" }}
        >
          회원가입
        </Button>

        {/* ID 찾기 / 비밀번호 재설정 */}
        <div className={classes.accBtns}>
          <Button className={classes.accBtn} onClick={() => handleNavigation(1)}>
            ID 찾기
          </Button>
          <Button className={classes.accBtn} onClick={() => handleNavigation(1)}>
            비밀번호 재설정
          </Button>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleCancel}>취소</Button>
      </DialogActions>
    </Dialog>
  );
};
