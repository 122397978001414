// createURL.js
const isCloud = process.env.REACT_APP_IS_NAVER_CLOUD === "1";
const localIp = process.env.REACT_APP_LOCAL_IP;
const cloudIp = process.env.REACT_APP_CLOUD_IP;

// 환경 변수 검증
if (!localIp || !cloudIp) {
  throw new Error("환경 변수가 올바르게 설정되지 않았습니다.");
}

// URL 생성 함수
const createURL = (port, path = "") => {
  const baseIp = isCloud ? cloudIp : localIp;
  const prefix = isCloud ? "https://" : "http://";
  return `${prefix}${baseIp}:${port}${path}`;
};

export default createURL;
