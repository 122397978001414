import axiosInstance from "./axiosInstance";
import createSpringURL from "./createSpringURL";

// 회원가입
export async function register(email, password, nickname, gender) {
  try {
    const response = await axiosInstance.post(
      createSpringURL("/signup"),
      { email, password, nickname, gender },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status === 201) {
      const data = response.data;
      console.log("회원가입 성공, 유저 ID:", data);

      // 회원가입 성공 시 로그인 호출
      await login(email, password);
    } else {
      console.error("회원가입 실패", response.statusText);
    }
  } catch (error) {
    console.error("회원가입 중 오류 발생:", error);
  }
}

// 회원탈퇴
export async function unregister() {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await axiosInstance.put(createSpringURL("/unregister"), null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      console.log("회원 탈퇴 성공");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    } else {
      console.error("회원 탈퇴 실패", response.statusText);
    }
  } catch (error) {
    console.error("회원 탈퇴 중 오류 발생:", error);
  }
}

// 회원조회
export async function getUserInfo() {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await axiosInstance.get(createSpringURL("/user"), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      const data = response.data;
      console.log("유저 정보:", data);
    } else {
      console.error("유저 정보 가져오기 실패", response.statusText);
    }
  } catch (error) {
    console.error("유저 정보 가져오는 중 오류 발생:", error);
  }
}

// 로그인
export async function login(email, password) {
  try {
    const response = await axiosInstance.post(
      createSpringURL("/login"),
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status === 200) {
      const { accessToken, refreshToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      console.log("로그인 성공");
    } else {
      console.error("로그인 실패", response.statusText);
    }
  } catch (error) {
    console.error("로그인 중 오류 발생:", error);
  }
}

// 로그아웃
export function logout() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  console.log("로그아웃 성공");
}
// 비밀번호 업데이트
export async function updatePassword(newPassword) {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const response = await axiosInstance.put(
      createSpringURL("/user"),
      { password: newPassword },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    if (response.status === 200) {
      const data = response.data;
      console.log("비밀번호 업데이트 성공, 유저 ID:", data);
    } else {
      console.error("비밀번호 업데이트 실패", response.statusText);
    }
  } catch (error) {
    console.error("비밀번호 업데이트 중 오류 발생:", error);
  }
}
