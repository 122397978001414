import { lazy } from "react";

import MainLayout from "components/layout/MainLayout/MainLayout.jsx";
import Loadable from "components/common/custom-ui/Loadable";

const Home = Loadable(lazy(() => import("components/pages/home/Home")));
const Chat = Loadable(lazy(() => import("components/pages/chat/Chat")));
const Select = Loadable(lazy(() => import("components/pages/select/Select")));
const Mypage = Loadable(lazy(() => import("components/pages/mypage/Mypage")));
const Account = Loadable(lazy(() => import("components/pages/account/Account")));
const Labs = Loadable(lazy(() => import("components/pages/Labs")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/chat",
      element: <Chat />,
    },
    {
      path: "/select",
      element: <Select />,
    },
    {
      path: "/mypage",
      element: <Mypage />,
    },
    {
      path: "/account",
      element: <Account />,
    },
    {
      path: "/labs",
      element: <Labs />,
    },
  ],
};

export default MainRoutes;
