import axios from "axios";
import createURL from "./createURL";
import { logout } from "./LibraryAPI";

const springPort = process.env.REACT_APP_SPRING_PORT;

if (!springPort) {
  throw new Error("환경 변수가 올바르게 설정되지 않았습니다.");
}

const axiosInstance = axios.create();

// 인터셉터 추가
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // accessToken이 만료된 경우
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");

      try {
        const response = await axios.post(createURL(springPort, "/refresh"), null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        if (response.status === 200) {
          const { accessToken } = response.data;
          localStorage.setItem("accessToken", accessToken);
          axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        console.error("토큰 갱신 실패", refreshError);
        logout(); // 로그아웃 호출
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
