import styled from "@emotion/styled";
import { Box as MuiBox, List as MuiList } from "@mui/material";

export const OuterBox = styled(MuiBox)`
  width: 250px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const List = styled(MuiList)`
  display: flex;
  flex-direction: column;
`;
