// moemoechat/app-react/src/MetaSetter.jsx
import { useEffect } from "react";
import { useAtom } from "jotai";
import { modeAtom } from "store/atom";

const MetaSetter = () => {
  const [mode] = useAtom(modeAtom);

  useEffect(() => {
    const titles = ["모에모에챗", "모에모에챗"];
    const ogUrls = ["http://www.moemoechat.com/", "http://www.moemoechat.com/"];
    const ogTitles = ["모에모에챗", "모에모에챗"];
    const ogTypes = ["website", "website"];
    const ogImages = [
      ~"http://www.moemoechat.com/luna_preview.png",
      "http://www.moemoechat.com/luna_preview.png",
    ];
    const ogDescriptions = ["메이드 루나를 꼬셔라 !", "마스코트와 모에모에한 대화를 나눠보세요!"];
    const index = mode === "moemoe" ? 0 : 1;

    const ver = {
      title: titles[index],
      ogUrl: ogUrls[index],
      ogTitle: ogTitles[index],
      ogType: ogTypes[index],
      ogImage: ogImages[index],
      ogDescription: ogDescriptions[index],
    };

    const setMetaProperty = (property, content) => {
      let metaTag = document.querySelector(`meta[property="${property}"]`);
      if (!metaTag) {
        metaTag = document.createElement("meta");
        metaTag.setAttribute("property", property);
        document.getElementsByTagName("head")[0].appendChild(metaTag);
      }
      metaTag.setAttribute("content", content);
    };

    setMetaProperty("og:url", ver.ogUrl);
    setMetaProperty("og:title", ver.ogTitle);
    setMetaProperty("og:type", ver.ogType);
    setMetaProperty("og:image", ver.ogImage);
    setMetaProperty("og:description", ver.ogDescription);

    document.title = ver.title;
  }, [mode]);

  return null;
};

export default MetaSetter;
