import { makeStyles } from "@mui/styles";
import { IoMdHome } from "react-icons/io";
import { MdImage } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { ImLab } from "react-icons/im";
import { IoIosChatboxes } from "react-icons/io";
import { FaDisplay } from "react-icons/fa6";
import { MdRestartAlt } from "react-icons/md";
import { IoVolumeMute } from "react-icons/io5";
import { IoVolumeMediumSharp } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";

const iconButtonBase = {
  cursor: "pointer",
  zIndex: 3000,
  borderRadius: "5px",
  padding: "1px",
};

export const useStyles = makeStyles({
  mainLayoutStyle: {
    backgroundColor: (props) => (props.isDark ? "rgba(32, 33, 36, 1)" : "rgba(242, 242, 242, 1)"),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },

  sideMenuWraper: {
    width: (props) => (props.drawerOpen ? "257px" : "0px"),
    transition: "width 0.3s ease",
    overflowX: "hidden",
    height: "100%",
    "@media (max-width: 1000px)": {
      position: "absolute",
    },
  },

  outletWraper: {
    flexGrow: 1,
    transition: "flex-basis 0.3s ease",
    flexBasis: (props) => (props.drawerOpen ? "calc(100% - 250px)" : "100%"),
    height: "100%",
    "@media (max-width: 1000px)": {
      flexBasis: "100%",
    },
  },

  iconButton: {
    ...iconButtonBase,
    color: (props) => (props.isDark ? "white" : "black"),
    width: "25px",
    height: "25px",
  },

  profileButton: {
    ...iconButtonBase,
    color: (props) => (props.isDark ? "white" : "black"),
    width: "20px",
    height: "20px",
  },

  profileButtonLoggedIn: {
    ...iconButtonBase,
    color: (props) => (props.isDark ? "white" : "black"),
    width: "20px",
    height: "20px",
  },

  viewSwitch: {
    ...iconButtonBase,
    color: (props) => (props.isDark ? "white" : "black"),
    width: "20px",
    height: "20px",
    marginTop: "3px",
    marginLeft: "3px",
  },

  labsButton: {
    ...iconButtonBase,
    color: (props) => (props.isDark ? "white" : "black"),
    width: "20px",
    height: "20px",
  },

  soundControl: {
    ...iconButtonBase,
    color: (props) => (props.isDark ? "white" : "black"),
    top: "15px",
    width: "25px",
    height: "25px",
  },

  profileDropdown: {
    position: "absolute",
    top: "30px",
    right: "0px",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    minWidth: "150px",
    padding: "10px 0",
  },

  profileMenuItem: {
    padding: "10px 20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },

  leftMenu: {
    position: "fixed",
    display: "flex",
    gap: "5px",
    top: "12px",
    left: "15px",
    zIndex: 2001,
  },

  rightMenu: {
    position: "fixed",
    display: "flex",
    gap: "5px",
    top: "12px",
    right: "15px",
  },
});

export const IconButton = ({ icon: Icon, className, onClick }) => (
  <Icon className={className} onClick={onClick} />
);

export const icons = {
  home: IoMdHome,
  side: IoIosChatboxes,
  labs: ImLab,
  viewSwitch: FaDisplay,
  chatRestart: MdRestartAlt,
  imageChange: MdImage,
  profile: FaUser,
  profileLoggedIn: FaUserCircle,
  mute: IoVolumeMute,
  volume: IoVolumeMediumSharp,
};
