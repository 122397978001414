// DrawerStyle.js

import styled from "@emotion/styled";
import { Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const EachGroup = styled(MuiBox)`
  margin-bottom: 10px;
  border: 1px solid #3a3a3a;
  border-radius: 8px;
`;

export const DrawerItemStyle = styled.div`
  width: 100%;
  text-align: left;
`;

export const GroupHeader = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 16px;
  background-color: #3a3a3a;
  color: #fff;
  transition: background-color 0.3s;
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${(props) => (props.isOpen ? "0px" : "8px")};
  border-bottom-right-radius: ${(props) => (props.isOpen ? "0px" : "8px")};

  &:hover {
    background-color: gray;
  }

  & .icon {
    margin-right: 8px;
  }

  & .title {
    color: white;
    font-size: 18px;
  }
`;

export const GroupTitle = styled(MuiTypography)`
  color: white;
  font-size: 18px;
`;
