//MuteControl.jsx
import React, { useState, useRef, useEffect } from "react";
import { ClickAwayListener } from "@mui/material";
import { playSingleAudio } from "utils/audioControl/playAudio";
import toBoolean from "utils/toBoolean";

const CustomSlider = ({ value, onChange, onMouseUp }) => {
  const sliderRef = useRef(null);

  const handleChange = (e) => {
    if (!sliderRef.current) return;
    const rect = sliderRef.current.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const percentage = 1 - Math.min(Math.max(y / rect.height, 0), 1);
    onChange(Math.round(percentage * 100));
  };

  const handleMouseDown = (e) => {
    handleChange(e);
    document.addEventListener("mousemove", handleChange);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleChange);
    document.removeEventListener("mouseup", handleMouseUp);
    onMouseUp();
  };

  return (
    <div
      ref={sliderRef}
      style={{
        height: "100px",
        width: "30px",
        background: "#ddd",
        position: "relative",
        cursor: "pointer",
      }}
      onMouseDown={handleMouseDown}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: `${value}%`,
          background: "#60e550",
        }}
      />
    </div>
  );
};

export const MuteControl = ({ MuteIcon, VolIcon, soundControl }) => {
  const getIsSoundOn = () => toBoolean(localStorage.getItem("isSoundOn"));
  const [toggleOn, setToggleOn] = useState(getIsSoundOn());
  const [volume, setVolume] = useState(() => {
    const savedVolume = localStorage.getItem("volume");
    return savedVolume !== null ? parseInt(savedVolume, 10) : 30;
  });
  const [showVolume, setShowVolume] = useState(false);
  const [controlLoaded, setControlLoaded] = useState(false);
  const volumeRef = useRef(null);

  const handleMouseEnter = () => setShowVolume(true);
  const handleMouseLeave = () => setShowVolume(false);
  const handleClickAway = () => setShowVolume(false);

  const handleVolChange = (newValue) => {
    setVolume(newValue);
  };

  const handleToggleMute = () => {
    const currentToggle = !toggleOn;
    localStorage.setItem("isSoundOn", currentToggle.toString());
    controlLoaded && currentToggle && playSingleAudio("volumeCheck.mp3");
    setToggleOn((prev) => !prev);
  };

  const handleDragEnd = () => {
    controlLoaded && getIsSoundOn() && playSingleAudio("volumeCheck.mp3");
    localStorage.setItem("volume", volume.toString());
  };

  useEffect(() => setControlLoaded(true), []);

  const volSliderStyle = {
    zIndex: "1100",
    position: "fixed",
    top: "33px",
    // right: "27px", => 다른 메뉴 존재 시
    right: "3px", // 단독 존재 시
    borderRadius: "5px",
    padding: "10px",
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ color: "white" }}
      >
        <div onClick={handleToggleMute}>
          {!toggleOn && <MuteIcon className={soundControl} style={{ marginTop: "-2px" }} />}
          {toggleOn && <VolIcon className={soundControl} style={{ marginTop: "-2px" }} />}
        </div>

        {showVolume && (
          <div ref={volumeRef} style={volSliderStyle}>
            <CustomSlider value={volume} onChange={handleVolChange} onMouseUp={handleDragEnd} />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
