import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline, Menu, MenuItem } from "@mui/material";
import { MuteControl } from "components/common/custom-ui/soundControl/MuteControl";
import { LoginComp } from "./login/LoginComp.jsx";
import { SideMenu } from "./sideMenu/SideMenu.jsx";
import { useStyles, IconButton, icons } from "./MainLayoutStyles";

import { useAtom } from "jotai";
import { isDarkAtom, viewAtom, isLoggedInAtom } from "store/atom";
import { useLocation } from "react-router-dom";
import { playSingleAudio, preloadAudio } from "utils/audioControl/playAudio";

const MainLayout = () => {
  const [loginComp, setLoginComp] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [curPage, setCurpage] = useState("/");

  const [isDark] = useAtom(isDarkAtom);
  const [login, setLogIn] = useAtom(isLoggedInAtom);
  const [, setView] = useAtom(viewAtom);

  const location = useLocation();
  const classes = useStyles({ isDark, drawerOpen });

  const handleDrawerToggle = () => {
    drawerOpen ? playSingleAudio("transition1.mp3") : playSingleAudio("transition2.mp3");
    setDrawerOpen((prev) => !prev);
  };

  const handleHomeClick = () => (window.location.href = "/");
  const handleMenuClose = () => setAnchorEl(null);
  const handleSwitchClick = () => setView((prev) => (prev === 1 ? 2 : 1));

  const handleProfileButtonClick = (e) => {
    login ? setAnchorEl(e.currentTarget) : setLoginComp(true);
  };

  const handleMenuItemClick = (work) => {
    window.location.href = work;
    if (work === "/") setLogIn(false);
    handleMenuClose();
  };

  useEffect(() => {
    setCurpage(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    preloadAudio("transition1.mp3");
    preloadAudio("transition2.mp3");
  }, []);

  return (
    <>
      <CssBaseline />
      <div className={classes.mainLayoutStyle}>
        {/* <div className={classes.sideMenuWraper}>
          <SideMenu drawerOpen={drawerOpen} />
        </div> */}
        <div className={classes.outletWraper}>
          <Outlet />
        </div>
      </div>

      {/* 좌우 메뉴 */}
      <div>
        <div className={classes.leftMenu}>
          {/* <IconButton
            icon={icons.side}
            className={classes.iconButton}
            onClick={handleDrawerToggle}
          /> */}
          <IconButton icon={icons.home} className={classes.iconButton} onClick={handleHomeClick} />
          {curPage.includes("chat") && (
            <IconButton
              icon={icons.viewSwitch}
              className={classes.viewSwitch}
              onClick={handleSwitchClick}
            />
          )}
        </div>
        <div className={classes.rightMenu}>
          {/* <IconButton
            icon={icons.labs}
            className={classes.labsButton}
            onClick={() => (window.location.href = "/labs")}
          /> */}
          {/* <IconButton
            icon={login ? icons.profileLoggedIn : icons.profile}
            className={login ? classes.profileButtonLoggedIn : classes.profileButton}
            onClick={handleProfileButtonClick}
          /> */}
          <MuteControl
            MuteIcon={icons.mute}
            VolIcon={icons.volume}
            soundControl={classes.soundControl}
          />
        </div>
      </div>

      {/* 숨겨진 항목 */}
      <LoginComp
        isLoggedIn={login}
        setLogIn={setLogIn}
        loginComp={loginComp}
        setLoginComp={setLoginComp}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleMenuItemClick("/mypage")}>My Page</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("/settings")}>Settings</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("/")}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default MainLayout;
