// styles.js
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  accBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "10px 0",
    gap: "10px",
  },

  accBtn: {
    cursor: "pointer",
    padding: "5px",
    width: "50%",
    textAlign: "center",
    backgroundColor: "#8d8ddb",
    textShadow: "0 0 1px black",
  },

  dialog: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  dialogTitle: {
    backgroundColor: "#8888ff",
    color: "white",
    textAlign: "center",
    fontSize: "1.5rem",
    padding: "10px 0",
  },
  dialogContent: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // gap: "3px",
  },
  dialogActions: {
    padding: "10px",
  },
  button: {
    margin: "10px 0",
    backgroundColor: "#a6a6c4",
    color: "white",
    "&:hover": {
      backgroundColor: "#6666cc",
    },
  },
  textField: {
    marginBottom: "20px",
  },
  paper: {
    borderRadius: "15px",
  },
}));

export default useStyles;
